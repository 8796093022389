import React from 'react';
import { List } from 'antd';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'; // 导入Link组件
import { blogData } from './blogsData'; // 确保这个路径是正确的
import './list.css'

const BlogList = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Drawing Prompts Hub - Blog</title>
        <link rel="canonical" href="https://drawingprompts.cc/blog" />
        <meta name="description" content="An AI Drawing Prompts Hub that utilizes algorithmic model for automatic prompt expansion and image-based description generation. Supports Midjourney, Stable Diffusion, and DALL·E prompt generation tools." />
      </Helmet>
      <List
        style={ {
            padding: '30px 0',
            margin: '20px 50px',
            background: '#fff',
            borderRadius: '6px'
        }}
          itemLayout="horizontal"
          dataSource={blogData}
          renderItem={(item) => (
            <List.Item
              // 使用Link组件包裹整个List.Item
              // 这样无论点击哪里都会导航到详情页
              key={item.id}
              onClick={() => window.location.href = `/blog/${item.id}`}
            >
              <List.Item.Meta
                title={<Link to={`/blog/${item.id}`}>{item.title}</Link>}
                description={item.date}
              />
            </List.Item>
          )}
        />
    </div>
  );
};

export default BlogList;