import React, { useState } from 'react';
import { Spin } from 'antd';

function ImageComponent({ src, alt = "", ...props }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    setError(true);
    setLoading(false);
  };

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  return (
    <div style={style}>
      <Spin spinning={loading}>
        {!error && <img src={src} alt={alt} onLoad={onLoad} onError={onError} {...props} />}
      </Spin>
      {error && <div>Image load failed.</div>}
    </div>
  );
}

export default ImageComponent;