const materialsRule = [
    {
      "pic": "https://i.postimg.cc/hGfvgRZT/ee61460c-d42f-4134-a35a-b9acb46e7734.jpg",
      "name": "Aluminum"
    },
    {
      "pic": "https://i.postimg.cc/T32XMjND/1888190c-804b-4975-9dbe-0e1ca9ee2af4.jpg",
      "name": "Brick"
    },
    {
      "pic": "https://i.postimg.cc/fLBFD13n/3f0bbde9-db16-493b-b0e3-4e7b7bec643c.jpg",
      "name": "Bronze"
    },
    {
      "pic": "https://i.postimg.cc/hjXDF8jr/7574f4af-1454-44af-a16f-21e3beb8db83.jpg",
      "name": "Cardboard"
    },
    {
      "pic": "https://i.postimg.cc/mDCnFh64/a9c347a1-8cca-4811-80e0-077f993fd98b.jpg",
      "name": "Ceramic"
    },
    {
      "pic": "https://i.postimg.cc/FRzRr5P0/daf33823-4d9b-4d60-b3dd-24709a47f443.jpg",
      "name": "Cotton"
    },
    {
      "pic": "https://i.postimg.cc/mgk6ym68/a2199ada-e646-40e0-b4a0-838a6c7cbd3d.jpg",
      "name": "Fabric"
    },
    {
      "pic": "https://i.postimg.cc/j57pQM2G/4565205e-557d-45fb-92db-3cd39d8ba9b2.jpg",
      "name": "Foil"
    },
    {
      "pic": "https://i.postimg.cc/W4FNC6t7/fca5af95-91f5-449b-a190-8a037f123ed9.jpg",
      "name": "Gold"
    },
    {
      "pic": "https://i.postimg.cc/pdDQf8xs/b08312c4-eeb0-4671-91fd-d29d1a451766.jpg",
      "name": "Leather"
    },
    {
      "pic": "https://i.postimg.cc/Prz6jB06/9a9af223-df34-4c65-b7e2-81f02c88f0e5.jpg",
      "name": "Nickel"
    },
    {
      "pic": "https://i.postimg.cc/k5dKg48K/c5d86984-26bd-4891-8bd5-f17a958c22ab.jpg",
      "name": "Nylon"
    },
    {
      "pic": "https://i.postimg.cc/J7JyGmrQ/eb7021b9-c0bd-447b-a98a-9eafdf679908.jpg",
      "name": "Paper"
    },
    {
      "pic": "https://i.postimg.cc/s23wxwCV/6a4713cc-e662-4b57-8f9e-848ac5581898.jpg",
      "name": "Plastic"
    },
    {
      "pic": "https://i.postimg.cc/NG1MQ4DJ/f7a86ab1-c4c3-4ad9-93a4-20926d684201.jpg",
      "name": "Quartz"
    },
    {
      "pic": "https://i.postimg.cc/52J2HF74/9314cdef-8940-482d-9861-38f16c0b2828.jpg",
      "name": "Sharink Wrap"
    },
    {
      "pic": "https://i.postimg.cc/G2Vf4Gjq/7a0782e1-ce68-4f3b-8223-e99e038315fb.jpg",
      "name": "Skin"
    },
    {
      "pic": "https://i.postimg.cc/PqrZw735/defe0558-9dc1-4012-acce-2fc6d432e1f3.jpg",
      "name": "Wooden"
    },
    {
      "pic": "https://i.postimg.cc/br2wn0fJ/d2877263-8849-48e5-859a-ccef84d31286.jpg",
      "name": "Yarn"
    }
  ];

export { materialsRule }