const colorsRule = [
    {
      "pic": "https://i.postimg.cc/qq8gYcbF/ebef78dc-5aa7-4b21-a5c6-f80e1ce0862c.jpg",
      "name": "Baby Blue Color"
    },
    {
      "pic": "https://i.postimg.cc/ncZk03Mq/25cdaea0-8428-4904-a386-a0248db75d9a.jpg",
      "name": "Baby Pink Color"
    },
    {
      "pic": "https://i.postimg.cc/FRBZPvdR/6e87c75a-2af4-4a8d-ba49-23cb85396077.jpg",
      "name": "Beige"
    },
    {
      "pic": "https://i.postimg.cc/T33cXhVw/21d318b7-9feb-41f8-85b1-db86bfc563e4.jpg",
      "name": "Blue"
    },
    {
      "pic": "https://i.postimg.cc/dt7XcHwp/4b0875e1-97ba-4ba9-9bcb-cd9cd585aaa9.jpg",
      "name": "Brown Color"
    },
    {
      "pic": "https://i.postimg.cc/kXrZ9NG5/7c8f048e-4461-4bbd-a63b-cc2fedceb78d.jpg",
      "name": "Citrus"
    },
    {
      "pic": "https://i.postimg.cc/1tvqBrdr/c62f5469-7ebb-4cf7-a803-ad2b2b0343b0.jpg",
      "name": "Coquelicot Color"
    },
    {
      "pic": "https://i.postimg.cc/8kvKyF4g/9b9077c2-cde0-4ad7-9dbb-5845210cb793.jpg",
      "name": "Cyan"
    },
    {
      "pic": "https://i.postimg.cc/y8LvJ01c/9ce80d07-c15e-4f4b-b572-b2e1b8a2b6f6.jpg",
      "name": "Cymk"
    },
    {
      "pic": "https://i.postimg.cc/9fr5zmhX/703893a0-aaf8-4ee8-ab3a-d857e4e3a4fd.jpg",
      "name": "Gold Color"
    },
    {
      "pic": "https://i.postimg.cc/66DdmY3M/d33467d6-b54e-4539-a0df-c661af747fee.jpg",
      "name": "Gray"
    },
    {
      "pic": "https://i.postimg.cc/k5kZ4Wsw/a1b7e52e-8031-4ad3-b741-970a9639f017.jpg",
      "name": "Grayscale Color"
    },
    {
      "pic": "https://i.postimg.cc/Vv9225jg/30700423-5a00-4f2b-8126-cdcb1e9e0433.jpg",
      "name": "Green"
    },
    {
      "pic": "https://i.postimg.cc/rpCW0kCb/c7cbacf9-debf-47c6-a7fe-19aad07a99c5.jpg",
      "name": "Hot Pink Color"
    },
    {
      "pic": "https://i.postimg.cc/gc5w0jbj/d15b5571-e7ae-44e1-8544-7c476b6ea173.jpg",
      "name": "Indigo"
    },
    {
      "pic": "https://i.postimg.cc/VkhZGXx1/a8949f3a-7865-43eb-aed2-022f61dc46cb.jpg",
      "name": "Lavender Color"
    },
    {
      "pic": "https://i.postimg.cc/Jh8dJRZC/4e504fe9-4f10-4834-96e4-4f0d462273d1.jpg",
      "name": "Magenta"
    },
    {
      "pic": "https://i.postimg.cc/XvmJY1th/f3adb0a4-96b2-4f2c-bb01-7d5329d49a73.jpg",
      "name": "Matte Black Color"
    },
    {
      "pic": "https://i.postimg.cc/tTnjvXSv/85616dd7-15eb-4b91-9803-9e1db7adb8c2.jpg",
      "name": "Mint Color"
    },
    {
      "pic": "https://i.postimg.cc/ZKcLg6y2/25e48118-5f8f-45ef-bca1-f518ec61f81e.jpg",
      "name": "Navy Blue"
    },
    {
      "pic": "https://i.postimg.cc/Qx0pSs2r/40adbbfd-fcce-4e76-80ed-9727fbf9ce9a.jpg",
      "name": "Neon Blue Color"
    },
    {
      "pic": "https://i.postimg.cc/8kf3qX13/8a884ad5-83e1-4500-abd0-46e618429b6c.jpg",
      "name": "Neon Green Color"
    },
    {
      "pic": "https://i.postimg.cc/MG8KQf9Z/331fa312-cc2d-4d58-a9ce-6c31fb6f1f35.jpg",
      "name": "Neon Orange Color"
    },
    {
      "pic": "https://i.postimg.cc/7hvyppjH/0900329e-c054-4979-ad8e-1d45d7867054.jpg",
      "name": "Neon Purple Color"
    },
    {
      "pic": "https://i.postimg.cc/fyFMTV3N/588ec1a8-aa20-463f-ade9-8ac53801dfe9.jpg",
      "name": "Neon Red Color"
    },
    {
      "pic": "https://i.postimg.cc/PJDRYJ3h/7e7084cb-53a5-481e-9b67-f5fee20f0a8a.jpg",
      "name": "Neon Yellow Color"
    },
    {
      "pic": "https://i.postimg.cc/ZR8f52CK/aeba3854-1516-4d78-8529-f7cb5c35636a.jpg",
      "name": "Orange"
    },
    {
      "pic": "https://i.postimg.cc/FH3B7Yfx/4d1abc8f-6c6b-4bf9-a609-f1cd26b17c46.jpg",
      "name": "Pink"
    },
    {
      "pic": "https://i.postimg.cc/qM0PyXT5/79163405-9424-4afa-8415-0991507fac7a.jpg",
      "name": "Red"
    },
    {
      "pic": "https://i.postimg.cc/pTRTg5jc/99cd7d2e-b3e7-44ff-b78f-757af20574ce.jpg",
      "name": "Rgb"
    },
    {
      "pic": "https://i.postimg.cc/BZ1FHHgH/e7dbf830-6784-44c7-be9a-857cb1698293.jpg",
      "name": "Silver Color"
    },
    {
      "pic": "https://i.postimg.cc/MT3SdnmQ/810a0f8e-2fb0-408b-b34c-1218552f0a55.jpg",
      "name": "Teal"
    },
    {
      "pic": "https://i.postimg.cc/L8P853B0/412fc5de-3700-4f3a-b02e-85f0a290568d.jpg",
      "name": "Turquoise"
    },
    {
      "pic": "https://i.postimg.cc/GmPn67jr/5416c002-eac8-4d6a-a56f-d9450c68e259.jpg",
      "name": "Vermillion"
    },
    {
      "pic": "https://i.postimg.cc/rFkD4bz2/ee6bf93e-b679-41c1-aa31-ed616e6d2154.jpg",
      "name": "Violet"
    },
    {
      "pic": "https://i.postimg.cc/RFStwDg0/cb5787e2-9520-4509-b16e-3eab61b05cba.jpg",
      "name": "White"
    },
    {
      "pic": "https://i.postimg.cc/nzGszGZt/85b42210-78f5-4894-80ce-44fa9304319f.jpg",
      "name": "Yellow"
    }
  ]

export { colorsRule }