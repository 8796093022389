const artistsRule = [
  {
    "pic": "https://i.postimg.cc/sgG42qLd/b811fe39-732f-4e1a-ac3a-0e85eb435b85.jpg",
    "name": "Alphonse Mucha"
  },
  {
    "pic": "https://i.postimg.cc/Nf3rdMdS/ccaf600a-a029-4b5a-bf36-16dba6d10979.jpg",
    "name": "Andy Warhol"
  },
  {
    "pic": "https://i.postimg.cc/rwyznrSh/ede15440-9309-41a8-9c58-b75942eb13e3.jpg",
    "name": "Art By Yoko Ono"
  },
  {
    "pic": "https://i.postimg.cc/05ZxyBgN/1ae362ed-7c92-4cf7-820c-cfa77fd4abd2.jpg",
    "name": "Banksy"
  },
  {
    "pic": "https://i.postimg.cc/1tBpTQ86/bb32aea1-2752-426f-a93a-f32996d27930.jpg",
    "name": "By Francisco De Goya"
  },
  {
    "pic": "https://i.postimg.cc/26zMpCS7/3e5cc68a-8e9d-4ea2-8a2e-5b9ea7774f69.jpg",
    "name": "Caravaggio"
  },
  {
    "pic": "https://i.postimg.cc/qMnPCbSn/6f1caf7c-499e-402e-a39c-4eaa7ad52660.jpg",
    "name": "David Hockney"
  },
  {
    "pic": "https://i.postimg.cc/bvGHsMFf/22b1ae98-6ad7-4875-bb83-cc836a5c8d13.jpg",
    "name": "Diego Rivera"
  },
  {
    "pic": "https://i.postimg.cc/zBTJXzCb/ff4090bf-3db5-4d23-83a7-1645f0fd34ac.jpg",
    "name": "Edgar Degas"
  },
  {
    "pic": "https://i.postimg.cc/PJfC8vrv/85e17602-b517-431e-8e34-b526028f31fe.jpg",
    "name": "Eugene Delacroix"
  },
  {
    "pic": "https://i.postimg.cc/qvNj3zzF/b3f21cad-972c-441e-b818-d2aebd9dbff9.jpg",
    "name": "Francis Bacon"
  },
  {
    "pic": "https://i.postimg.cc/hPzSZ1wP/572dee9b-63b0-49e2-a624-0c7577d30ee5.jpg",
    "name": "Frida Kahlo"
  },
  {
    "pic": "https://i.postimg.cc/QCMMML0p/99a7f6f0-4572-4e93-8aac-ec3e8f5ad12d.jpg",
    "name": "Garald Brom"
  },
  {
    "pic": "https://i.postimg.cc/HxrySyCG/dec56bab-326d-4ce8-9573-f7073aa622f8.jpg",
    "name": "Gustav Klimt"
  },
  {
    "pic": "https://i.postimg.cc/4332wBLW/7c18bd05-7495-415e-b615-e5986b53549b.jpg",
    "name": "Henri Matisse"
  },
  {
    "pic": "https://i.postimg.cc/NfSRZbjN/bd2a4905-402b-434a-ad1f-615bef2ac103.jpg",
    "name": "Jack Kirby"
  },
  {
    "pic": "https://i.postimg.cc/PJ6PbfP3/92eecb12-9a71-433d-84ec-4eea9e80fb49.jpg",
    "name": "Jackson Pollock"
  },
  {
    "pic": "https://i.postimg.cc/L8D0zrTq/7e27408a-d02c-4884-8f1f-e22ce051a749.jpg",
    "name": "Jean Michel Basquiat"
  },
  {
    "pic": "https://i.postimg.cc/QCN3qp7s/01a45b39-3aef-45a3-bd8e-113b09c4be84.jpg",
    "name": "Jmw Turner"
  },
  {
    "pic": "https://i.postimg.cc/N0MYT81c/66132ab9-daad-4258-9488-80bfb6b9cbc1.jpg",
    "name": "Johannes Vermeer"
  },
  {
    "pic": "https://i.postimg.cc/ZKCrfX4B/bd293419-7e37-4636-98be-fe6be252aa28.jpg",
    "name": "Leonardo Da Vinci"
  },
  {
    "pic": "https://i.postimg.cc/gjLfsN0C/6389a0f2-ebe7-4528-97b7-e863b7adf975.jpg",
    "name": "Marc Chagall"
  },
  {
    "pic": "https://i.postimg.cc/RF5s94CD/a4671087-a20e-4362-a219-495528f6bfab.jpg",
    "name": "Marcel Duchamp"
  },
  {
    "pic": "https://i.postimg.cc/RCwd7kCM/10bd9648-1d52-41be-8651-dd8cc09b9c84.jpg",
    "name": "Mark Rothko"
  },
  {
    "pic": "https://i.postimg.cc/x1n4N0KD/7db8ca72-d47b-4d6a-92a1-0c700143470b.jpg",
    "name": "Michelangelo"
  },
  {
    "pic": "https://i.postimg.cc/7LNtnjnG/04c07727-bff1-48fb-bfee-d69e60f13a2a.jpg",
    "name": "Monet"
  },
  {
    "pic": "https://i.postimg.cc/gjB3zHq2/bc64079a-ceda-4d7f-a0a4-c27e098190ea.jpg",
    "name": "Paul Cezanne"
  },
  {
    "pic": "https://i.postimg.cc/cLBPFKsL/6eea23a9-43f1-43a3-9452-890127a12f1c.jpg",
    "name": "Paul Gauguin"
  },
  {
    "pic": "https://i.postimg.cc/K8S9Sm93/5e881d64-43a6-4783-90db-5aacfa43bd53.jpg",
    "name": "Paul Klee"
  },
  {
    "pic": "https://i.postimg.cc/65m8y3sT/92fd3d86-81c0-449f-ba9a-beb5fbfecae6.jpg",
    "name": "Picasso"
  },
  {
    "pic": "https://i.postimg.cc/F15sVfhx/d9532182-4e3e-4d95-bb8c-cd337028373c.jpg",
    "name": "Pierre Auguste Renoir"
  },
  {
    "pic": "https://i.postimg.cc/d3hWv3Pr/5b5e693a-b240-4918-a5f0-1a0520219d49.jpg",
    "name": "Piet Mondrian"
  },
  {
    "pic": "https://i.postimg.cc/DwTtQQ3N/7a50b103-7a3a-4e29-8766-1f4e75e11b18.jpg",
    "name": "Rembrandt"
  },
  {
    "pic": "https://i.postimg.cc/TY7Dg0bd/79cfd4ce-eced-4ee6-ba5e-4a171e5cdca7.jpg",
    "name": "Rene Magritte"
  },
  {
    "pic": "https://i.postimg.cc/pT3n3L2m/de7aa8a9-e946-4881-84ba-1100633b8bc4.jpg",
    "name": "Roy Lichtenstein"
  },
  {
    "pic": "https://i.postimg.cc/3NxsG3Mx/2e8ffc9c-8dee-4bbc-988b-5415d3c9de35.jpg",
    "name": "Salvador Dali"
  },
  {
    "pic": "https://i.postimg.cc/LXpTwDNd/b7df47ed-5828-4888-81a7-d31fe4bf6f14.jpg",
    "name": "Sandro Botticelli"
  },
  {
    "pic": "https://i.postimg.cc/PqZvGkRJ/80b47623-9691-48d2-aac1-44f47c1af66c.jpg",
    "name": "Takashi Murakami"
  },
  {
    "pic": "https://i.postimg.cc/4ddPby3q/5fc56e9c-ed1b-4631-a646-9b7f61723325.jpg",
    "name": "Van Gogh"
  },
  {
    "pic": "https://i.postimg.cc/x1XbRmGK/83c95559-d664-4a25-8799-00e43fc05d16.jpg",
    "name": "Wassily Handinsky"
  },
  {
    "pic": "https://i.postimg.cc/NMjyKr09/ccd92df5-4a3f-4f0a-89fe-6595ab07ee0e.jpg",
    "name": "Willem De Koonig"
  },
  {
    "pic": "https://i.postimg.cc/50hP41xr/8f2eedca-eea8-4ef9-8e29-0245b03a5af2.jpg",
    "name": "Yayoi Kusama"
  },
  {
    "pic": "https://i.postimg.cc/CKyp2y8c/0c8e2edd-3690-46a8-9f07-649f761ad0f0.jpg",
    "name": "Yoji Shinkawa"
  }
]

export { artistsRule }