const aspectRatios = ['', '1:1', '5:4', '3:2', '7:4', '16:9', '2:1', '9:16', '1:2'];

const versions = ['', '6', '5.2', '5.1', '5', '4', '3', '2', '1', 'niji']; 

const qualities = ['', '.25', '.5', '1'];

const tiles = ['No', 'Yes'];

export {
    aspectRatios,
    versions,
    qualities,
    tiles
}