// App.tsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/index'; // 引入首页组件
import MjGenerator from './pages/MjGenerator/MjGenerator';
import MjExample from './pages/MjExample/index';
import ImageToText from './pages/ImageToText/index';
import AIPromptsGenerator from './pages/AIPromptsGenerator/index';
import PrivacyPolicy from './pages/PrivacyPolicy/index';
import TermsOfService from './pages/TermsOfService/index';
import BlogList from './pages/Blog/list';
import BlogDetail from './pages/Blog/detail';
import { Dropdown, Button } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css'; // 引入样式
import './App.css'

const App = () => {
  useEffect(() => {
    AOS.init({
      duration : 800  // 动画持续时间，可根据需要进行调整
    });
  }, []);

  const items = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="./midjourney-prompts-generator">
          Midjourney Prompts Generator
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="./best-midjourney-prompts">
          Best Midjourney Prompts
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="./image-to-text">
          AI Image To Text
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="./art-prompts-generator">
          AI Art Prompts Generator
        </a>
      ),
    },
  ];

  return (
    <div class="app">
      <div className='page-nav'>
        <div className='logo'>
          <a href="./" title="Drawing Prompts Hub">Drawing Prompts Hub</a></div>
        <div class="nav-links">
        <Dropdown
          menu={{
            items,
          }}
          placement="bottom"
          arrow
        >
          <Button type="text">Menu</Button>
        </Dropdown>
          <a href="/blog" title="Blog"><Button type="text">Blog</Button></a>
        </div>
      </div>
      <div className='page-main'>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/midjourney-prompts-generator" element={<MjGenerator />} />
            <Route path="/best-midjourney-prompts" element={<MjExample />} />
            <Route path="/image-to-text" element={<ImageToText />} />
            <Route path="/art-prompts-generator" element={<AIPromptsGenerator />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
          </Routes>
        </Router>
      </div>
      <div className='page-footer'>
        <span>Copyright © 2024 Drawing Prompts Hub. All rights reserved.</span>
        <span><a href='./privacy-policy'>Privacy Policy</a> | <a href='./terms-of-service'>Terms of Service</a></span>
      </div>
    </div>
  );
};

export default App;
