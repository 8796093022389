const stylesRule = [
    {
      "pic": "https://i.postimg.cc/h4YysLTr/5b630392-ff1e-4d33-bd04-c8a5bdebca8b.jpg",
      "name": "16-bit"
    },
    {
      "pic": "https://i.postimg.cc/528QTVG0/ccd1ca01-fb44-413c-84d1-a6618a643421.jpg",
      "name": "1800s"
    },
    {
      "pic": "https://i.postimg.cc/PfZHm58P/0757300f-d7cf-4328-bc85-04d9608d7457.jpg",
      "name": "1980s"
    },
    {
      "pic": "https://i.postimg.cc/J0RpDxk3/ab18a857-8ff1-462e-b84b-46ba857c9587.jpg",
      "name": "4-bit"
    },
    {
      "pic": "https://i.postimg.cc/ZqTzm8fN/2424f9b1-70a6-49b4-856e-f20817ab4037.jpg",
      "name": "8-bit"
    },
    {
      "pic": "https://i.postimg.cc/9Xx5hwTR/2e827724-708c-4a35-9c2a-d5a3994abbc6.jpg",
      "name": "Amber"
    },
    {
      "pic": "https://i.postimg.cc/3wMWFcxT/98bd5f4a-e9b2-4131-a272-609fa71d4e4f.jpg",
      "name": "Anatomical Drawing"
    },
    {
      "pic": "https://i.postimg.cc/G3gNNwwv/4b053dd5-c8a8-4f58-8ed9-ef84ea8504ac.jpg",
      "name": "Ancient"
    },
    {
      "pic": "https://i.postimg.cc/PJk5mpXQ/f1c64ca6-5f3c-4ca0-bdac-853463ba5eb9.jpg",
      "name": "Anime"
    },
    {
      "pic": "https://i.postimg.cc/bNkvNRRV/9900d1d3-8840-4f1b-b37b-ddeab9dc7c46.jpg",
      "name": "Antimatter"
    },
    {
      "pic": "https://i.postimg.cc/w3KkpkkY/6d478345-640b-443b-ad2a-f12c9d77af51.jpg",
      "name": "Arabic"
    },
    {
      "pic": "https://i.postimg.cc/wvNBycpB/ee5b185d-3724-49e5-b8bb-114074c940ba.jpg",
      "name": "Black Hole"
    },
    {
      "pic": "https://i.postimg.cc/4y8T2mvc/0b25f1da-b2f3-4a86-8c37-e55a889630b8.jpg",
      "name": "Blocky"
    },
    {
      "pic": "https://i.postimg.cc/9QgYXVbB/21abf3ae-d3bd-4c46-934e-3f7d40d373f4.jpg",
      "name": "Blueprint Drawing"
    },
    {
      "pic": "https://i.postimg.cc/C5rWH9YH/2b975696-82c1-4b71-8059-77ebf0bfb9dc.jpg",
      "name": "Carbon Fiber"
    },
    {
      "pic": "https://i.postimg.cc/FKD5HYqB/04107f2a-ebdf-4ddd-bcfd-5e1805d36f7b.jpg",
      "name": "Caribbean"
    },
    {
      "pic": "https://i.postimg.cc/yxxGdn9J/3f40b96f-f5f9-4911-9f01-6323bceb2eb8.jpg",
      "name": "Cartoon"
    },
    {
      "pic": "https://i.postimg.cc/wBxZZ6vc/6fd5a587-cf43-4787-9b33-4e3e1f722b78.jpg",
      "name": "Carved Lacquer"
    },
    {
      "pic": "https://i.postimg.cc/xTWZfvdC/4b60281d-e421-4393-8070-85aee8da12ac.jpg",
      "name": "Celestial"
    },
    {
      "pic": "https://i.postimg.cc/5tnyWNmx/d1964fdc-c285-4389-bdcd-f9cf1869710f.jpg",
      "name": "Cellulose"
    },
    {
      "pic": "https://i.postimg.cc/MTv4Zd1n/7a76b455-569f-4118-b2c6-ded4f0df6c61.jpg",
      "name": "Charcoal Style"
    },
    {
      "pic": "https://i.postimg.cc/6QHgND4s/7be22e3e-57ad-4d5f-b8d5-3d598f80db9f.jpg",
      "name": "Chromatic"
    },
    {
      "pic": "https://i.postimg.cc/ZnmMPZ1d/a4c520ed-4064-4b62-9b7b-3cd27c77d516.jpg",
      "name": "Comicbook"
    },
    {
      "pic": "https://i.postimg.cc/NFGxf03p/b61e3e85-85c1-4c96-ac98-76a8dd9fc676.jpg",
      "name": "Comicbook Drawing"
    },
    {
      "pic": "https://i.postimg.cc/HL4W4WNw/448d0bb8-039b-40db-95ba-cf77e84d6436.jpg",
      "name": "Computer Chip"
    },
    {
      "pic": "https://i.postimg.cc/7Pc4zT6v/865bc1e8-e412-4714-ad3c-78be154f29b8.jpg",
      "name": "Concept Art"
    },
    {
      "pic": "https://i.postimg.cc/HW0KYcy4/a36fbaa6-e8ef-42ce-8f98-8e161d1185de.jpg",
      "name": "Coral"
    },
    {
      "pic": "https://i.postimg.cc/MTFC1hz0/996ef7b7-8e77-4d1d-81c9-e2646ffbe930.jpg",
      "name": "Cyberpunk"
    },
    {
      "pic": "https://i.postimg.cc/wjYdf8L0/4725bd66-6edf-440b-bf60-d4addc3df655.jpg",
      "name": "Dangerous"
    },
    {
      "pic": "https://i.postimg.cc/XY6pwPJ0/d379ddb7-5d53-4c92-9ad3-18ee7a485681.jpg",
      "name": "Dark Matter"
    },
    {
      "pic": "https://i.postimg.cc/FRLZnqjV/09cb1e83-6a2a-4b20-a945-3096fa6d858c.jpg",
      "name": "Da Vinci"
    },
    {
      "pic": "https://i.postimg.cc/c4QzJKtB/7ec7e136-ea65-4267-9f3a-bbd716429a3d.jpg",
      "name": "Da Vinci Drawing"
    },
    {
      "pic": "https://i.postimg.cc/x8HbQVCt/e9d31254-10d8-490a-88de-abf792cd5d36.jpg",
      "name": "Deep Sea"
    },
    {
      "pic": "https://i.postimg.cc/D0YwJCMR/381df787-031d-497d-a311-21abd46f18ca.jpg",
      "name": "Diabolic"
    },
    {
      "pic": "https://i.postimg.cc/1tv92Cs4/489e925e-7248-4962-bcf2-23829c441c0f.jpg",
      "name": "Diffraction Grading"
    },
    {
      "pic": "https://i.postimg.cc/fy50Hjgv/65b1acba-aafe-4cc9-9d44-cc6ecf4cd15d.jpg",
      "name": "Dna"
    },
    {
      "pic": "https://i.postimg.cc/NMn0LJVS/daf7f67f-e2d1-4915-af3e-b285d4daec2a.jpg",
      "name": "Dots"
    },
    {
      "pic": "https://i.postimg.cc/W4R6qSRD/b3473536-b5b8-4dc9-a6da-a6d4999cee72.jpg",
      "name": "Dripping Paint"
    },
    {
      "pic": "https://i.postimg.cc/rpG3YDMp/3cfb67d5-daa0-49c2-a796-08b84a1d6897.jpg",
      "name": "Dune"
    },
    {
      "pic": "https://i.postimg.cc/gj3gr5j2/8ff9729f-ad5e-4651-b3e1-daff02bb78c1.jpg",
      "name": "Electrical"
    },
    {
      "pic": "https://i.postimg.cc/qqH9m2Cg/6713259a-9f16-41cc-a7d9-764f4f5ef393.jpg",
      "name": "Electronic Circuitry"
    },
    {
      "pic": "https://i.postimg.cc/28bZdv7n/cb4812bc-3811-4433-9e9c-de452d98b716.jpg",
      "name": "Etching"
    },
    {
      "pic": "https://i.postimg.cc/2ydxCZ9q/9e9cadaa-c5c1-42c7-953b-96a192d899ab.jpg",
      "name": "Extraterrestrial"
    },
    {
      "pic": "https://i.postimg.cc/gjKBNQWB/a3d87003-1838-4ab2-838d-5e7db8e604ed.jpg",
      "name": "Fiber Optic"
    },
    {
      "pic": "https://i.postimg.cc/VvzCBH46/66e8c5fb-c330-464d-942f-8fb93f9aba99.jpg",
      "name": "Fibonacci"
    },
    {
      "pic": "https://i.postimg.cc/HsRLTh5y/213acf03-5e7f-433a-b89a-a6d3923028ba.jpg",
      "name": "Floral"
    },
    {
      "pic": "https://i.postimg.cc/z3m4cKbV/5986db7d-af89-4e3e-837b-870cf6bf2cda.jpg",
      "name": "Flower Of Life"
    },
    {
      "pic": "https://i.postimg.cc/fTL3zdr6/94c5cef8-551c-471c-957d-2bc1f5e5d4de.jpg",
      "name": "Fossil"
    },
    {
      "pic": "https://i.postimg.cc/P571syf3/44b4ac20-067a-45d5-8cb4-d446da3b52e8.jpg",
      "name": "Fractal"
    },
    {
      "pic": "https://i.postimg.cc/PrRjmv5s/1c6bbdc9-dff0-4d25-8cfa-ba65c51fde79.jpg",
      "name": "Futuristic"
    },
    {
      "pic": "https://i.postimg.cc/8cGhXCkT/35bd50a3-db6e-4066-91ba-f25f07b55d56.jpg",
      "name": "Galactic"
    },
    {
      "pic": "https://i.postimg.cc/C5ZVtwc6/844dad0b-9918-45b9-9bf0-09245885b94b.jpg",
      "name": "Gasoline"
    },
    {
      "pic": "https://i.postimg.cc/FHb1Gxdk/d9702ab6-7050-48bd-9b31-a9394fc33f80.jpg",
      "name": "Glass"
    },
    {
      "pic": "https://i.postimg.cc/kX1TGxX8/b4b5d5b6-0ca8-47b3-8490-c582639166ba.jpg",
      "name": "Glass Blowing"
    },
    {
      "pic": "https://i.postimg.cc/tCfK10B9/3c96f647-e613-4d06-85c3-ebaf59f2985e.jpg",
      "name": "Glitchart"
    },
    {
      "pic": "https://i.postimg.cc/Ghy4TRbQ/d8fbd373-6794-4f90-82e1-62e852bf6665.jpg",
      "name": "Gouache"
    },
    {
      "pic": "https://i.postimg.cc/6QjZLhgL/dd04c103-f2b1-46ad-b6ea-d89d7e76ddf3.jpg",
      "name": "Graffitti"
    },
    {
      "pic": "https://i.postimg.cc/BQ9qyKGT/34026ba9-aacd-4657-ba66-3d53e7b6ad49.jpg",
      "name": "Graphic Novel"
    },
    {
      "pic": "https://i.postimg.cc/T3PYqX6F/fa7a43f1-da15-45cd-b522-813da064d0e9.jpg",
      "name": "Gummies"
    },
    {
      "pic": "https://i.postimg.cc/MTfn0Jyq/e588655d-bb64-4d59-b7e5-e0f622ab858c.jpg",
      "name": "Helix"
    },
    {
      "pic": "https://i.postimg.cc/QCN3qp7s/01a45b39-3aef-45a3-bd8e-113b09c4be84.jpg",
      "name": "Hell"
    },
    {
      "pic": "https://i.postimg.cc/8545DkBp/99ecf93a-2553-4ef2-96ad-ebb9625bdf15.jpg",
      "name": "Higgs Boson"
    },
    {
      "pic": "https://i.postimg.cc/RFsPjf8v/4e246fa0-c368-41b1-81d9-bef53eda534c.jpg",
      "name": "Horror"
    },
    {
      "pic": "https://i.postimg.cc/ryh1NqX2/be1872d7-49cc-43ef-bde7-f90f16ee768f.jpg",
      "name": "Ice Age"
    },
    {
      "pic": "https://i.postimg.cc/901JG9d6/b0df6293-1c9e-4d3a-be6a-631c09775e60.jpg",
      "name": "Icy"
    },
    {
      "pic": "https://i.postimg.cc/GpZPYQZ8/24ad3461-0614-4d0d-8d7a-d127405322dc.jpg",
      "name": "Jurassic"
    },
    {
      "pic": "https://i.postimg.cc/pTLvffzv/0672ea3a-3f6c-41a8-bd5c-61e5c950b7da.jpg",
      "name": "Kaleidoscope"
    },
    {
      "pic": "https://i.postimg.cc/d13Mkqct/958f1e83-8731-4e82-a2fe-a36623feb991.jpg",
      "name": "Knitted"
    },
    {
      "pic": "https://i.postimg.cc/gcv9xtfh/7490719e-f502-47d0-a665-de9c099a29f0.jpg",
      "name": "Latex"
    },
    {
      "pic": "https://i.postimg.cc/rwqqy2sz/63622c0b-8819-49ce-9a8a-2a0db35ab61e.jpg",
      "name": "Lightspeed"
    },
    {
      "pic": "https://i.postimg.cc/DyZc98dz/b627e96b-9462-47ec-a22a-9abbe161a395.jpg",
      "name": "Liquid"
    },
    {
      "pic": "https://i.postimg.cc/sgbG02Kb/82c44bbd-76c4-43df-b3a6-41311a5d553b.jpg",
      "name": "Logo"
    },
    {
      "pic": "https://i.postimg.cc/QCN3qp7s/01a45b39-3aef-45a3-bd8e-113b09c4be84.jpg",
      "name": "Love"
    },
    {
      "pic": "https://i.postimg.cc/JnLDMdfq/82f4dd31-4e13-4d02-9189-809d4107e691.jpg",
      "name": "Lsd"
    },
    {
      "pic": "https://i.postimg.cc/FK1G01zq/9fceb1aa-6dc2-445c-bc61-5e6a6880f14f.jpg",
      "name": "Magma"
    },
    {
      "pic": "https://i.postimg.cc/s2VxLZ76/d0497a02-cae5-4f3e-abaa-cbe785e225c6.jpg",
      "name": "Mandala"
    },
    {
      "pic": "https://i.postimg.cc/wBVyxk9Y/d3f8c702-1fd0-4a9c-8fb8-9d8178ae99c8.jpg",
      "name": "Marble Statue"
    },
    {
      "pic": "https://i.postimg.cc/nrYcTFMb/f69ae30e-74ce-488b-9b9e-22e41e4a48b3.jpg",
      "name": "Matter"
    },
    {
      "pic": "https://i.postimg.cc/gc4Dtvh5/b415bff7-7a7d-44d7-9022-9561375cea8e.jpg",
      "name": "Merkaba"
    },
    {
      "pic": "https://i.postimg.cc/PJM1gkNY/26b65541-33e5-42fd-bd21-61a6d943cb04.jpg",
      "name": "Metallic"
    },
    {
      "pic": "https://i.postimg.cc/LsTRgCqv/744c4db5-a0c9-43d7-b595-fff8512e8268.jpg",
      "name": "Mitochondria"
    },
    {
      "pic": "https://i.postimg.cc/52mN8NTw/17651ae4-35a8-4093-97e8-fb3624100ea1.jpg",
      "name": "Molecular"
    },
    {
      "pic": "https://i.postimg.cc/zvMRZX8L/c7742c85-7e15-4364-be98-a1b551769257.jpg",
      "name": "Multidimensional"
    },
    {
      "pic": "https://i.postimg.cc/MKDhDMZK/a1b4f56e-9f0a-4f39-8767-2b3f614f522f.jpg",
      "name": "Nasa"
    },
    {
      "pic": "https://i.postimg.cc/X7RnRySK/632bc3a7-75f6-42d2-a747-7261daf2c59a.jpg",
      "name": "Nebula"
    },
    {
      "pic": "https://i.postimg.cc/7LWCTZJK/e62a55eb-ac9c-492b-af2b-437fcd5805fa.jpg",
      "name": "Neon"
    },
    {
      "pic": "https://i.postimg.cc/Y9RcjhsP/2c8f56da-2a7a-4919-adc4-eea311603ad5.jpg",
      "name": "Nuclear"
    },
    {
      "pic": "https://i.postimg.cc/0jgbcXfm/e866525b-ee39-4d72-86b3-56ab72d1e01c.jpg",
      "name": "Oil Painting"
    },
    {
      "pic": "https://i.postimg.cc/Y0B3c3RK/19f4f60b-6625-4010-afe0-100f36e82620.jpg",
      "name": "Old Photograph"
    },
    {
      "pic": "https://i.postimg.cc/sfnXNXgK/150dab11-796c-4564-bfad-3bc040639df4.jpg",
      "name": "Orbital"
    },
    {
      "pic": "https://i.postimg.cc/tgVSLYb6/ada493f5-c338-4e91-bfe6-f7b0e210f43d.jpg",
      "name": "Origami"
    },
    {
      "pic": "https://i.postimg.cc/G2dwckqY/1f0778ac-72a2-46f6-846f-611492fa4d6f.jpg",
      "name": "Ornamental"
    },
    {
      "pic": "https://i.postimg.cc/tT8X91nH/69139d23-cc15-45af-ae74-9cb4d71c27ff.jpg",
      "name": "Pastel"
    },
    {
      "pic": "https://i.postimg.cc/gjLKQ78K/06ca319b-d8ec-4c05-86ab-39448d09830c.jpg",
      "name": "Photorealistic"
    },
    {
      "pic": "https://i.postimg.cc/GmLXhXCb/9c92d9a9-7353-4cc1-a7bc-944ec47401f3.jpg",
      "name": "Pixelart"
    },
    {
      "pic": "https://i.postimg.cc/PxWDDkW9/61d6f023-b6a7-47bc-9d30-168e1b370c15.jpg",
      "name": "Polka"
    },
    {
      "pic": "https://i.postimg.cc/T2Hdc4t4/557ac089-3d3e-49ea-9894-191d91b30112.jpg",
      "name": "Pre Historic"
    },
    {
      "pic": "https://i.postimg.cc/cJpxSgnG/fe11703b-edce-4392-903c-797c9980f90c.jpg",
      "name": "Prokaryotic"
    },
    {
      "pic": "https://i.postimg.cc/bY00D1Lr/36b7f019-dd13-4435-95d5-f466037ec401.jpg",
      "name": "Quasar"
    },
    {
      "pic": "https://i.postimg.cc/pL1Vs1VT/fa0a2dd6-d4dc-49c1-b7cc-1525279b7023.jpg",
      "name": "Radioactive"
    },
    {
      "pic": "https://i.postimg.cc/2SYnzynv/db5d4d05-bceb-4848-a3ff-9bcbbe18d796.jpg",
      "name": "Ray Tracing"
    },
    {
      "pic": "https://i.postimg.cc/PJ7R8ZzS/3f29cf29-721f-4f05-af94-b071f9200fd4.jpg",
      "name": "Realistic"
    },
    {
      "pic": "https://i.postimg.cc/s2qFhkjY/4556122c-8cd8-4956-9a93-f62fb4f4f632.jpg",
      "name": "Renaissance"
    },
    {
      "pic": "https://i.postimg.cc/NjbB5C2D/65689af2-2bb3-41d7-b132-11e4524903bc.jpg",
      "name": "Retro"
    },
    {
      "pic": "https://i.postimg.cc/jS3mppJp/35082167-c262-4b6c-bde9-65d5cfce81bc.jpg",
      "name": "Risograph"
    },
    {
      "pic": "https://i.postimg.cc/SxZbyXHt/0732004c-81dc-457b-b4c0-bf8f643bf4b6.jpg",
      "name": "Sacred Geometry"
    },
    {
      "pic": "https://i.postimg.cc/Pr86RCMk/5c731853-18ae-40fa-af8d-93aff61e9291.jpg",
      "name": "Sketch Drawing"
    },
    {
      "pic": "https://i.postimg.cc/LsZLHPnH/52b80bf3-67d1-4687-9f19-04a1174f558c.jpg",
      "name": "Slime"
    },
    {
      "pic": "https://i.postimg.cc/Nf5Mqcyt/256c5a4c-a58e-42aa-b290-5dd89b7355a9.jpg",
      "name": "Space"
    },
    {
      "pic": "https://i.postimg.cc/1zp1hWcs/0ce2029e-0846-41bd-bd0f-68674ac1db6d.jpg",
      "name": "Splatter Paint"
    },
    {
      "pic": "https://i.postimg.cc/hvh757WW/c7062e7d-b9f0-415c-8f47-09bd28ae8e28.jpg",
      "name": "Spray Paint"
    },
    {
      "pic": "https://i.postimg.cc/dtLMtQmW/3a5aaaea-e2bd-4a58-8c40-9b6b4049b1da.jpg",
      "name": "Squiggles"
    },
    {
      "pic": "https://i.postimg.cc/wMH91C2W/ff4651a8-5e29-4a40-86e2-feb2d42ad022.jpg",
      "name": "Stitching"
    },
    {
      "pic": "https://i.postimg.cc/Qdcjhxh2/724b7889-94da-42fa-b475-1c6d00de76fc.jpg",
      "name": "Stranger Things"
    },
    {
      "pic": "https://i.postimg.cc/Kzkqh4yL/8b8478ab-7aff-4738-96ce-0b9e976c1088.jpg",
      "name": "Street Art"
    },
    {
      "pic": "https://i.postimg.cc/CLGf609K/e3cab57e-6ddd-41d3-80af-e8a23258fdb7.jpg",
      "name": "Surreal"
    },
    {
      "pic": "https://i.postimg.cc/jds1Lx6V/a2f46828-184d-44b9-9b7a-6644f0d9c241.jpg",
      "name": "Symmetric"
    },
    {
      "pic": "https://i.postimg.cc/XNZfLTVZ/dbc59046-bafe-4ced-bd1c-47ebaef87fdc.jpg",
      "name": "Synthwave"
    },
    {
      "pic": "https://i.postimg.cc/0QXzD26t/cd5ceb18-73eb-4e18-9f8c-573ecf9464cd.jpg",
      "name": "Technological"
    },
    {
      "pic": "https://i.postimg.cc/MGttQVz1/5ea31931-e823-480a-ae17-cf9e1f62624e.jpg",
      "name": "Tron"
    },
    {
      "pic": "https://i.postimg.cc/q704RV7z/645c5e58-373b-4874-bbad-086e12a23649.jpg",
      "name": "Tropical"
    },
    {
      "pic": "https://i.postimg.cc/RCTfV4Vn/071a0701-3037-417e-9743-0f3367d20201.jpg",
      "name": "Ultra Modern"
    },
    {
      "pic": "https://i.postimg.cc/RCjFBYwn/9977c2c1-67c6-4e5f-a327-1df53e894b85.jpg",
      "name": "Ultrasonic"
    },
    {
      "pic": "https://i.postimg.cc/SNkKRMty/f57f160d-ffc0-41f4-8fa8-897d35a3b516.jpg",
      "name": "Veins"
    },
    {
      "pic": "https://i.postimg.cc/htdHTCYT/2e41a128-49dd-40dc-8ffb-90d11d0805ca.jpg",
      "name": "Volcanic"
    },
    {
      "pic": "https://i.postimg.cc/kgGWpzCR/37d71a1b-69c9-4fd3-a449-08aa73c8cd3e.jpg",
      "name": "Wet Paint"
    },
    {
      "pic": "https://i.postimg.cc/pV1jnkz2/ca27ea14-3afe-40ce-9a07-138dd68aceb2.jpg",
      "name": "Wild West"
    },
    {
      "pic": "https://i.postimg.cc/85mHSv07/b22a65a9-30f4-40ed-a4d3-8588b43d37e9.jpg",
      "name": "Wind"
    },
    {
      "pic": "https://i.postimg.cc/FsBxNNDV/b0405b6a-ef7f-4c82-afda-01655709b3be.jpg",
      "name": "Wormhole"
    },
    {
      "pic": "https://i.postimg.cc/9Mmmx53v/444cdaea-343c-4049-b836-bfdfb2f0743f.jpg",
      "name": "Wrinkled"
    }
  ];
export { stylesRule }