import React, { useState } from 'react';
import { Upload, message, Spin, Image, Input } from 'antd';
import { Helmet } from "react-helmet";
import { InboxOutlined } from '@ant-design/icons';

const API_URL = "https://api-inference.huggingface.co/models/Salesforce/blip-image-captioning-large";
const API_TOKEN = "Bearer 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";

const { Dragger } = Upload;

const ImageUploader = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Only JPG or PNG files can be uploaded.');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('File size must be less than 10MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleUpload = async (file) => {
    setLoading(true);
    try {
      const data = await file.arrayBuffer();
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Authorization': API_TOKEN
        },
        body: data
      });
      const jsonResponse = await response.json();

      const imageURL = URL.createObjectURL(file);
      setImageUrl(imageURL);
      setResult(jsonResponse?.[0]?.generated_text || '');

    } catch (err) {
      message.error(`Failed: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px', padding: '30px 50px' }}>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Drawing Prompts Hub - AI Image To Text</title>
        <link rel="canonical" href="https://drawingprompts.cc/image-to-text" />
        <meta name="description" content="Transform visual data into textual insights with AI Image To Text technology. Our advanced AI extracts text from images, unlocking valuable information from signs, documents, and more, for efficient data processing and analysis."/>
      </Helmet>
       <div>
        <h1 data-aos="fade-up" style={{
            fontWeight: 'bold',
            fontSize: '40px',
            color: '#333',
            margin: '20px 0 20px'
        }}> AI Image To Text </h1>
      </div>
      <Dragger
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={({ file, onSuccess }) => {
          handleUpload(file).then(() => onSuccess(), message.error);
        }}
      >
        {loading ? <Spin /> : (
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
        )}
        {imageUrl &&
          <Image
            width={200}
            preview={false}
            src={imageUrl}
          />
        }
        <p className="ant-upload-text">Click or drag the file here to upload</p>
      </Dragger>
      
      {result && <Input.TextArea rows={4} readOnly value={result} />}
    </div>
  );
}

export default ImageUploader;