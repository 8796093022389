import React, { useEffect, useState } from 'react';
// import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row, Space } from 'antd';
import style from './configDrawer.module.css';
import { stylesRule } from './stylesRule';
import { lightingRule } from './lightingRule';
import { cameraRule } from './cameraRule';
import { artistsRule } from './artistsRule';
import { colorsRule } from './colorsRule';
import { materialsRule } from './materialsRule';

const ConfigDrawer = ({ show, showType, drawerData, onClose, onSubmit, onClear }) => {
    const [checkList, setCheckList] = useState([])
    const [configRule, setConfigRule] = useState([])

    useEffect(() => {
        if (showType === 'styles') {
            setConfigRule(stylesRule)
        } else if (showType === 'lighting') {
            setConfigRule(lightingRule)
        } else if (showType === 'camera') {
            setConfigRule(cameraRule)
        } else if (showType === 'artists') {
            setConfigRule(artistsRule)
        } else if (showType === 'colors') {
            setConfigRule(colorsRule)
        } else if (showType === 'materials') {
            setConfigRule(materialsRule)
        }
        setCheckList(drawerData)
    }, [showType, drawerData])

    const onClearAll = () => {
        onClear(showType)
        onClose()
    }

    const onContinue = () => {
        onSubmit(checkList)
        onClose()
    }

    const check = (name) => {
        setCheckList(preState => {
            let array = [].concat(preState)
            if (array.includes(name)) {
                const index = array.indexOf(name);
                array.splice(index, 1);
            } else {
                array.push(name);
            }
            return array
        })
    }

    return (
        <>
        <Drawer
            title="Styles"
            width={720}
            onClose={onClose}
            open={show}
            styles={{
            body: {
                paddingBottom: 80,
                background: '#f6f6f6'
            },
            }}
            extra={
                <Space>
                    <Button onClick={onClearAll}>Clear All & Close</Button>
                    <Button onClick={onContinue} type="primary">
                        { checkList.length ? `continue (${checkList.length})` : 'continue' }
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
                {
                    configRule.map(item => {
                        return (
                            <Col key={item.name} span={8}>
                                <div className={checkList.includes(item.name) ? style.mjParameterWrapperActive : style.mjParameterWrapper} onClick={() => check(item.name)}>
                                    <div className={style.innerWrapper}>
                                        <div className={style.mjParameterImgWrapper}>
                                            <img src={item.pic} alt="" className={style.imgFluid} />
                                        </div>
                                        <div className={style.parameterName}>{item.name}</div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
            </Form>
        </Drawer>
        </>
    );
};
export default ConfigDrawer;