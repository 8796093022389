const cameraRule = [
    {
      "pic": "https://i.postimg.cc/BZNLfpMT/92debd9e-dc9b-4a7d-89ae-ae97ad72e7e0.jpg",
      "name": "360 Panorama"
    },
    {
      "pic": "https://i.postimg.cc/hG9vy1X7/99b16ca8-131e-4fb4-b161-12129b62b426.jpg",
      "name": "Dslr"
    },
    {
      "pic": "https://i.postimg.cc/zXCJ1GJ9/36417b74-216b-4148-92bf-6688da56d9a0.jpg",
      "name": "Electron Microscope"
    },
    {
      "pic": "https://i.postimg.cc/Gph3Knn6/9528a71b-0d0e-4e03-8723-22de1aee4c8b.jpg",
      "name": "Macro Lens"
    },
    {
      "pic": "https://i.postimg.cc/3RJSS9tL/4e5c961b-7320-4e32-b03b-7eb727507ce4.jpg",
      "name": "Magnification"
    },
    {
      "pic": "https://i.postimg.cc/SNFMrRT5/df95158c-9024-43bc-a880-0b9b8b8cb873.jpg",
      "name": "Microscopy"
    },
    {
      "pic": "https://i.postimg.cc/Cx9JHS8x/b9ed0a46-ceda-446d-b454-fa6b0a12e512.jpg",
      "name": "Miniature Faking"
    },
    {
      "pic": "https://i.postimg.cc/L5GxsS27/5b50f9fb-0bbc-49e9-b010-68f81a3f6dbd.jpg",
      "name": "Panorama"
    },
    {
      "pic": "https://i.postimg.cc/sDmghgt5/f79202d8-b748-45e8-90c9-8992c38ae570.jpg",
      "name": "Pinhole Lens"
    },
    {
      "pic": "https://i.postimg.cc/yYYf7fBs/b06d59ae-de21-4b5e-b021-471538b699c6.jpg",
      "name": "Satellite Imagery"
    },
    {
      "pic": "https://i.postimg.cc/wjR7JpH2/98ec04cc-d9da-4911-bdde-45bdbd63642e.jpg",
      "name": "Super Resolution Microscopy"
    },
    {
      "pic": "https://i.postimg.cc/yxJkP4c7/d41da9a2-531a-4c67-800d-85c239096e78.jpg",
      "name": "Telephoto Lens"
    },
    {
      "pic": "https://i.postimg.cc/2SCLsJf7/e13755ca-8a32-4f40-aa9c-6e54cf03f107.jpg",
      "name": "Telescope Lens"
    },
    {
      "pic": "https://i.postimg.cc/DyZhJPFP/0e0dcf68-f68d-469e-b957-c63f0ae7ea2b.jpg",
      "name": "Ultra Wide Angle Lens"
    },
    {
      "pic": "https://i.postimg.cc/Prk60tVp/9c6dcb75-977b-4a72-b22c-97c9895bcf66.jpg",
      "name": "Wide Angle Lens"
    }
  ]

export { cameraRule }