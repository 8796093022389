import React, { useEffect, useState } from "react";
import { Card, Form, Select, Input, InputNumber, Button, message, Slider } from "antd";
import { Row, Col } from 'antd';
import style from './MjGenerator.module.css'
import StylesDrawer from './Drawer/configDrawer'
import { Helmet } from "react-helmet";
import {
  aspectRatios,
  versions,
  qualities,
  tiles
} from './utils'
const { Option } = Select;

export default function App() {
  const [messageApi, contextHolder] = message.useMessage();

  const [mainIdea, setMainIdea] = useState('')
  const [resultIdea, setResultIdea] = useState('')
  const [formIdea, setFormIdea] = useState('') // 缓存一下form拼接的值
  const [showConfigDrawer, setShowConfigDrawer] = useState(false)
  const [showConfigType, setShowConfigType] = useState('')
  const [form, setForm] = useState({
    aspectRatio: '',
    version: '',
    quality: '',
    stylize: '',
    chaos: '',
    stop: '',
    repeat: '',
    weird: '',
    seed: '',
    tile: 'No',
    no: '',
  });
  const [stylesRule, setStylesRule] = useState({})
  const [lightingRule, setLightingRule] = useState({})
  const [cameraRule, setCameraRule] = useState({})
  const [artistsRule, setArtistsRule] = useState({})
  const [colorsRule, setColorsRule] = useState({})
  const [materialsRule, setMaterialsRule] = useState({})

  useEffect(() => {
    const result = Object.entries(form)
      .filter(([, value]) => {
        return (value && value !== 'No') || value === 0
      })
      .map(([key, value]) => `--${key} ${value}`)
      .join(' ');
    setFormIdea(result)
    setResultIdea(`/imagine prompt: ${mainIdea} ${result}`)
  }, [form, mainIdea])

  useEffect(() => {

    const currentStylesRuleInString = Object.entries(stylesRule)
      .map(([key, value]) => `${key}::${value}`)
      .join(' ');

    const currentLightingRuleInString = Object.entries(lightingRule)
      .map(([key, value]) => `${key}::${value}`)
      .join(' ');

    const currentArtistsRuleInString = Object.entries(cameraRule)
      .map(([key, value]) => `${key}::${value}`)
      .join(' ');

    const currentCameraRuleInString = Object.entries(artistsRule)
      .map(([key, value]) => `${key}::${value}`)
      .join(' ');

    const currentColorRuleInString = Object.entries(colorsRule)
      .map(([key, value]) => `${key}::${value}`)
      .join(' ');

    const currentMaterialsRuleInString = Object.entries(materialsRule)
      .map(([key, value]) => `${key}::${value}`)
      .join(' ');

    setResultIdea((prevIdea) => {
      let previousStylesRuleInString = prevIdea.replace(/\/imagine prompt: (.*)$/, '');
      previousStylesRuleInString = previousStylesRuleInString.trim();

      // console.log('previousStylesRuleInString', prevIdea, previousStylesRuleInString)
      // 如果previousStylesRuleInString没有内容，则直接返回新的idea
      if (!previousStylesRuleInString) {
        return `/imagine prompt: ${mainIdea} ${currentStylesRuleInString} ${currentLightingRuleInString} ${currentArtistsRuleInString} ${currentCameraRuleInString} ${currentColorRuleInString} ${currentMaterialsRuleInString} ${formIdea}`;
      }

      // 替换旧的stylesRule值，返回新的idea
      let newIdea = prevIdea.replace(previousStylesRuleInString, currentStylesRuleInString);
      return newIdea;
    });
  }, [stylesRule, formIdea, mainIdea, lightingRule, cameraRule, artistsRule, colorsRule, materialsRule]);

  const drawerData = React.useMemo(() => {
    switch (showConfigType) {
      case 'styles':
        return Object.keys(stylesRule)
      case 'lighting':
        return Object.keys(lightingRule)
      case 'camera':
        return Object.keys(cameraRule)
      case 'artists':
        return Object.keys(artistsRule)
      case 'colors':
        return Object.keys(colorsRule)
      case 'materials':
        return Object.keys(materialsRule)
      default:
        return []
    }
  }, [showConfigType]);

  // 只在mainIdea更改时更新mainIdea变量
  const handleMainIdeaChange = (e) => {
    const newMainIdea = e.target.value
    const oldMainIdea = mainIdea
    setMainIdea(newMainIdea)
    setResultIdea(prevState => {
      let newResult = prevState.replace(`/imagine prompt: ${oldMainIdea}`, '').trim();
      newResult = `/imagine prompt: ${newMainIdea} ${newResult}`;
      return newResult;
    });
  };
  // 修改每个处理表单项更改的函数
  const handleChange = (value, field) => {
    setForm(prevState => {
      return { ...prevState, [field]: value };
    });
  };

  // 新增的复制到剪贴板函数
  const handleCopy = () => {
    navigator.clipboard.writeText(resultIdea)
      .then(() => {
        // 成功复制到剪贴板后的操作，例如弹出提示
        messageApi.open({
          type: 'success',
          content: 'Copied successfully!',
        });
      })
      .catch(err => {
        // 复制失败时的操作
        messageApi.open({
          type: 'fail',
          content: 'Copy failed',
        });
        console.error('复制失败', err);
      });
  };

  const onReset = () => {
    setForm((prevState) => {
      return {
        ...prevState,
        aspectRatio: "",
        version: "",
        quality: "",
        stylize: "",
        chaos: "",
        stop: "",
        repeat: "",
        weird: "",
        seed: "",
        tile: 'No',
        no: '',
      }
    })
    setStylesRule({})
    setLightingRule({})
    setCameraRule({})
    setArtistsRule({})
    setColorsRule({})
    setMaterialsRule({})
    messageApi.open({
      type: 'success',
      content: 'Done!',
    });
  };

  const clickDrawer = (type) => {
    setShowConfigType(type)
    setShowConfigDrawer(true)
  }

  const onStylesClose = (type) => {
    setShowConfigType(type)
    setShowConfigDrawer(false)
  }

  const handleConfigValue = (value) => {
    let newObj = value.reduce((acc, cur) => ({ ...acc, [cur]: 3 }), {});
    switch (showConfigType) {
      case 'styles':
        setStylesRule(newObj)
        break;
      case 'lighting':
        setLightingRule(newObj)
        break;
      case 'camera':
        setCameraRule(newObj)
        break;
      case 'artists':
        setArtistsRule(newObj)
        break;
      case 'colors':
        setColorsRule(newObj)
        break;
      case 'materials':
        setMaterialsRule(newObj)
        break;
      default:
        break;
    }
  }

  const handleConfigClearAll = (type) => {
    switch (type) {
      case 'styles':
        setStylesRule({})
        break;
      case 'lighting':
        setLightingRule({})
        break;
      case 'camera':
        setCameraRule({})
        break;
      case 'artists':
        setArtistsRule({})
        break;
      case 'colors':
        setColorsRule({})
        break;
      case 'materials':
        setMaterialsRule({})
        break;
      default:
        break;
    }
  }

  const onConfigSliderChange = (key, value, type) => {
    switch (type) {
      case 'styles':
        let newStylesObj = { ...stylesRule };
        newStylesObj[key] = value;
        setStylesRule(newStylesObj)
        break;
      case 'lighting':
        let newLightingObj = { ...lightingRule };
        newLightingObj[key] = value;
        setLightingRule(newLightingObj)
        break;
      case 'camera':
        let newCameraObj = { ...cameraRule };
        newCameraObj[key] = value;
        setCameraRule(newCameraObj)
        break;
      case 'artists':
        let newArtistsObj = { ...artistsRule };
        newArtistsObj[key] = value;
        setArtistsRule(newArtistsObj)
        break;
      case 'colors':
        let newColorsObj = { ...colorsRule };
        newColorsObj[key] = value;
        setColorsRule(newColorsObj)
        break;
      case 'materials':
        let newMaterialsObj = { ...materialsRule };
        newMaterialsObj[key] = value;
        setMaterialsRule(newMaterialsObj)
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Drawing Prompts Hub - Midjourney Prompts Generator</title>
        <link rel="canonical" href="https://drawingprompts.cc/midjourney-prompts-generator" />
        <meta name="description" content="Unleash your creativity with the Midjourney Prompts Generator. Craft unique narratives, enhance your writing, and spark artistic inspiration with our AI-powered tool designed to fuel your imaginative journey." />
      </Helmet>
      <div style={{ padding: "40px 0" }}>
        {contextHolder}
        <h1 style={{ textAlign: "center", marginBottom: "40px" }}>
          Midjourney Prompts Generator
        </h1>
        <Card title="Main Idea and Result" style={{
          width: "90%",
          height: "auto",
          marginTop: "20px",
          margin: "20px auto",
          borderRadius: "12px",
          boxShadow: "5px 5px 15px rgba(0,0,0,0.2)" // 添加的 boxShadow
        }}>
          <Form>
            <Form.Item label="">
              <Input.TextArea placeholder="Start typing your main idea..." value={mainIdea} onChange={handleMainIdeaChange} />
            </Form.Item>
            <Form.Item label="">
              <Input.TextArea value={resultIdea} readOnly />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleCopy}>Copy Prompt</Button>
            </Form.Item>
          </Form>
        </Card>

        <Card title="Prompt Generator" style={{
          width: "90%",
          height: "auto",
          marginTop: "20px",
          margin: "20px auto",
          borderRadius: "12px",
          boxShadow: "5px 5px 15px rgba(0,0,0,0.2)" // 添加的 boxShadow
        }}>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  label="Aspect Ratio"
                  tooltip="Change the aspect ratio (the width-to-height ratio) of the generated image. Default is 1:1."
                >
                  <Select style={{ width: '100%' }} value={form.aspectRatio} onChange={(value) => handleChange(value, 'aspectRatio')}>
                    {aspectRatios.map((ratio) => (
                      <Option key={ratio} value={ratio}>
                        {ratio ? ratio : '----'}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Version" tooltip="Specify the Midjourney model to use. Default is 5.2.">
                  <Select style={{ width: '100%' }} value={form.version} onChange={(value) => handleChange(value, 'version')}>
                    {versions.map((version) => (
                      <Option key={version} value={version}>
                        {version ? version : '----'}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Quality" tooltip="Decide how much time is spent generating an image (higher number, higher quality). Default is 1.">
                  <Select style={{ width: '100%' }} value={form.quality} onChange={(value) => handleChange(value, 'quality')}>
                    {qualities.map((quality) => (
                      <Option key={quality} value={quality}>
                        {quality ? quality : '----'}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Stylize" tooltip="Low values will closely match the prompt but are less artistic. High values will be very artistic but less connected to the prompt. Default is 100.">
                  <InputNumber style={{ width: '100%' }} placeholder="0 to 1000" min={0} max={1000} value={form.stylize} onChange={(value) => handleChange(value, 'stylize')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Chaos" tooltip="Influence how varied your image grid will be. Higher chaos means more unusual and unexpected results. Default is 0.">
                  <InputNumber style={{ width: '100%' }} placeholder="0 to 100" min={0} max={100} value={form.chaos} onChange={(value) => handleChange(value, 'chaos')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Stop" tooltip="Create blurrier, less detailed results by stopping your job partway through. Default is 100.">
                  <InputNumber style={{ width: '100%' }} min={10} max={100} placeholder="10 to 100" value={form.stop} onChange={(value) => handleChange(value, 'stop')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Repeat" tooltip="Run your prompt multiple times.">
                  <InputNumber style={{ width: '100%' }} min={2} max={40} placeholder="2 to 40" value={form.repeat} onChange={(value) => handleChange(value, 'repeat')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Weird" tooltip="Introduce quirky and offbeat qualities to your images, resulting in unique and unexpected outcomes. Default is 0.">
                  <InputNumber style={{ width: '100%' }} min={0} max={3000} placeholder="0 to 3000" value={form.weird} onChange={(value) => handleChange(value, 'weird')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Tile" tooltip="Generate images that can be used as repeating tiles to create seamless patterns for fabrics, wallpapers and textures.">
                  <Select style={{ width: '100%' }} value={form.tile} onChange={(value) => handleChange(value, 'tile')}>
                    {tiles.map((tile) => (
                      <Option key={tile} value={tile}>
                        {tile}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Seed" tooltip="If you use the same seed number and prompt, you will get similar final images. Default is random.">
                  <InputNumber style={{ width: '100%' }} min={0} defaultValue={form.seed} onChange={(value) => handleChange(value, 'seed')} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Exclude" tooltip="Tell the Midjourney Bot what not to include in your image.">
                  <Input style={{ width: '100%' }} placeholder="Avoid these terms in the image" value={form.no} onChange={(e) => handleChange(e.target.value, 'no')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ justifyContent: 'center' }}>
              <Col>
                <Button className={style.btnSecondary}
                  onClick={() => clickDrawer('styles')}
                >
                  {Object.keys(stylesRule).length ? `Styles（${Object.keys(stylesRule).length}）` : 'Styles'}
                </Button>
              </Col>
              <Col>
                <Button className={style.btnSecondary} onClick={() => clickDrawer('lighting')}>
                  {Object.keys(lightingRule).length ? `Lighting（${Object.keys(lightingRule).length}）` : 'Lighting'}
                </Button>
              </Col>
              <Col>
                <Button className={style.btnSecondary} onClick={() => clickDrawer('camera')}>
                  {Object.keys(cameraRule).length ? `Camera（${Object.keys(cameraRule).length}）` : 'Camera'}
                </Button>
              </Col>
              <Col>
                <Button className={style.btnSecondary} onClick={() => clickDrawer('artists')}>
                  {Object.keys(artistsRule).length ? `Artists（${Object.keys(artistsRule).length}）` : 'Artists'}
                </Button>
              </Col>
              <Col>
                <Button className={style.btnSecondary} onClick={() => clickDrawer('colors')}>
                  {Object.keys(colorsRule).length ? `Colors（${Object.keys(colorsRule).length}）` : 'Colors'}
                </Button>
              </Col>
              <Col>
                <Button className={style.btnSecondary} onClick={() => clickDrawer('materials')}>
                  {Object.keys(materialsRule).length ? `Materials（${Object.keys(materialsRule).length}）` : 'Materials'}
                </Button>
              </Col>
            </Row>
            {
              Object.entries(stylesRule).map(([key, value]) => (
                <Row key={key} gutter={[16, 16]} style={{ marginTop: '8px', justifyContent: 'center' }}>
                  <Col span={6} style={{ textAlign: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {`Styles: ${key}`}
                  </Col>
                  <Col span={8}>
                    <Slider
                      defaultValue={3}
                      max={5} min={1}
                      value={value}
                      onChange={(value) => onConfigSliderChange(key, value, 'styles')}
                    />
                  </Col>
                </Row>
              ))
            }
            {
              Object.entries(lightingRule).map(([key, value]) => (
                <Row key={key} gutter={[16, 16]} style={{ marginTop: '8px', justifyContent: 'center' }}>
                  <Col span={6} style={{ textAlign: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {`Lighting: ${key}`}
                  </Col>
                  <Col span={8}>
                    <Slider
                      defaultValue={3}
                      max={5} min={1}
                      value={value}
                      onChange={(value) => onConfigSliderChange(key, value, 'lighting')}
                    />
                  </Col>
                </Row>
              ))
            }
            {
              Object.entries(cameraRule).map(([key, value]) => (
                <Row key={key} gutter={[16, 16]} style={{ marginTop: '8px', justifyContent: 'center' }}>
                  <Col span={6} style={{ textAlign: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {`Camera: ${key}`}
                  </Col>
                  <Col span={8}>
                    <Slider
                      defaultValue={3}
                      max={5} min={1}
                      value={value}
                      onChange={(value) => onConfigSliderChange(key, value, 'camera')}
                    />
                  </Col>
                </Row>
              ))
            }
            {
              Object.entries(artistsRule).map(([key, value]) => (
                <Row key={key} gutter={[16, 16]} style={{ marginTop: '8px', justifyContent: 'center' }}>
                  <Col span={6} style={{ textAlign: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {`Artists: ${key}`}
                  </Col>
                  <Col span={8}>
                    <Slider
                      defaultValue={3}
                      max={5} min={1}
                      value={value}
                      onChange={(value) => onConfigSliderChange(key, value, 'artists')}
                    />
                  </Col>
                </Row>
              ))
            }
            {
              Object.entries(colorsRule).map(([key, value]) => (
                <Row key={key} gutter={[16, 16]} style={{ marginTop: '8px', justifyContent: 'center' }}>
                  <Col span={6} style={{ textAlign: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {`Colors: ${key}`}
                  </Col>
                  <Col span={8}>
                    <Slider
                      defaultValue={3}
                      max={5} min={1}
                      value={value}
                      onChange={(value) => onConfigSliderChange(key, value, 'colors')}
                    />
                  </Col>
                </Row>
              ))
            }
            {
              Object.entries(materialsRule).map(([key, value]) => (
                <Row key={key} gutter={[16, 16]} style={{ marginTop: '8px', justifyContent: 'center' }}>
                  <Col span={6} style={{ textAlign: 'right', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {`Materials: ${key}`}
                  </Col>
                  <Col span={8}>
                    <Slider
                      defaultValue={3}
                      max={5} min={1}
                      value={value}
                      onChange={(value) => onConfigSliderChange(key, value, 'materials')}
                    />
                  </Col>
                </Row>
              ))
            }
            <Row gutter={[16, 16]} style={{ marginTop: '20px', justifyContent: 'center' }}>
              <Col span={4} style={{ textAlign: 'center' }}>
                <Button htmlType="button" type="primary" onClick={onReset}>
                  Clear All
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>

        <Card title='How to Use the Midjourney Prompt Generator' style={{
          width: "90%",
          height: "auto",
          marginTop: "20px",
          margin: "20px auto",
          borderRadius: "12px",
          boxShadow: "5px 5px 15px rgba(0,0,0,0.2)" // 添加的 boxShadow
        }}>
          <div className={style.howToUseWrap}>
            <p>Midjourney’s AI-powered image generation bot is <i>incredibly</i> powerful.But unless you have a photographic memory, it can be really hard to remember all of the parameters and styling options the Midjourney bot supports! That's where this prompt helper comes in handy.</p>
            <p>
              Start by typing the main concept for your image.
              Then set any parameters （like aspect ratio
              to change your image dimensions, or chaos
              to increase variation） you'd like the Midjourney bot to follow.
              From there, you can apply some of our style presets and "weight" parts of your prompt
              （Midjourney will strongly follow a weighting of 5, and lightly consider a weighting of 1）.
            </p>
            <p>Once you've got your prompt dialed in, copy it to your clipboard and run it in Midjourney.</p>
          </div>
        </Card>
        <Card style={{
          width: "90%",
          height: "auto",
          marginTop: "20px",
          margin: "20px auto",
          borderRadius: "12px",
          boxShadow: "5px 5px 15px rgba(0,0,0,0.2)" // 添加的 boxShadow
        }}>
          <div className={style.howToUseWrap}>
            <h1>Midjourney prompt guide</h1>
            <p>A Prompt is a short text phrase that the Midjourney Bot interprets to produce an image. The Midjourney Bot breaks down the words and phrases in a prompt into smaller pieces, called tokens, that are compared to its training data and then used to generate an image. A well-crafted prompt can help make unique and exciting images.</p>
            <p>A basic prompt can be as simple as a single word, phrase or emoji 😊.</p>
            <p>The Midjourney Bot works best with simple, short sentences that describe what you want to see. Avoid long lists of requests and instructions. Instead of: Show me a picture of lots of blooming California poppies, make them bright, vibrant orange, and draw them in an illustrated style with colored pencils Try: Bright orange California poppies drawn with colored pencils</p>
            <h2>Prompting Notes</h2>
            <h3>Word Choice</h3><p>
              Word choice matters. More specific synonyms work better in many circumstances. Instead of big, try tiny, huge, gigantic, enormous, or immense.
            </p>

            <h3>Plural words and Collective Nouns</h3>
            <p>
              Plural words leave a lot to chance. Try specific numbers. "Three cats" is more specific than "cats." Collective nouns also work, “flock of birds” instead of "birds.”
            </p>

            <h3>Focus on What you Want</h3>
            <p>
              It is better to describe what you want instead of what you don’t want. If you ask for a party with “no cake,” your image will probably include a cake. To ensure an object is not in the final image, try advanced prompting using thee --no parameter.
            </p>

            <h3>Prompt Length and Details</h3>
            <p>
              Prompts can be simple. A single word or emoji will work. However, short prompts rely on Midjourney’s default style, allowing it to fill in any unspecified details creatively. Include any element that is important to you in your prompt. Fewer details means more variety but less control.
            </p>
            <p>
              Try to be clear about any context or details that are important to you. Think about:<br></br>
              Subject: person, animal, character, location, object<br></br>
              Medium: photo, painting, illustration, sculpture, doodle, tapestry<br></br>
              Environment: indoors, outdoors, on the moon, underwater, in the city<br></br>
              Lighting: soft, ambient, overcast, neon, studio lights<br></br>
              Color: vibrant, muted, bright, monochromatic, colorful, black and white, pastel<br></br>
              Mood: sedate, calm, raucous, energetic<br></br>
              Composition: portrait, headshot, closeup, birds-eye view
            </p>

          </div>
        </Card>
        <StylesDrawer
          show={showConfigDrawer}
          showType={showConfigType}
          drawerData={drawerData}
          onClose={onStylesClose}
          onSubmit={handleConfigValue}
          onClear={handleConfigClearAll}
        />
      </div>
    </div>
  );
}