import React, { useState, useRef } from 'react';
import Typed from 'typed.js';
import { Helmet } from "react-helmet";
import { Input, Button, message, Row, Col, Select, Form, Card } from 'antd';
const { Option } = Select;

// 定义API接口地址，这里为了展示，我用了假的API接口地址，实际情况下请替换为你自己的接口地址
const apiUrls = {
  SD: 'https://api-inference.huggingface.co/models/Gustavosta/MagicPrompt-Stable-Diffusion',
  MJ: 'https://api-inference.huggingface.co/models/BEE-spoke-data/smol_llama-101M-midjourney-messages',
  DALLE: 'https://api-inference.huggingface.co/models/Gustavosta/MagicPrompt-Dalle',
};

function ComputeContent() {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');
  const [mode, setMode] = useState('SD'); // 默认选择第一个模式
  // 创建 ref 来存储 Typed 实例
  const typedRef = useRef(null);
  const el = React.useRef(null);

  const handleModeChange = (value) => {
    setMode(value); // 更新模式
    setInput(''); // 清空输入框
    setResult(''); // 清空结果框
  };

  const handleClick = async () => {
    try {
      if(input.trim() === '') {
        return
      }

      const response = await fetch(apiUrls[mode], {
        method: 'POST',
        headers: {
          Authorization: "Bearer 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",
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inputs: input })
      });

      if (response.status === 200) {
        const resultData = await response.json();
        const newResult = resultData?.[0]?.generated_text;

        if (newResult === result) {
          message.warning('There might be no more content to generate, you can continue to try.');
        } else {
          // 在创建新的 `Typed` 实例之前，先销毁前一个实例
          if (typedRef.current) {
            typedRef.current.destroy();
          }
          // 创建一个新的 `Typed` 实例并把它保存到 ref 中
          typedRef.current = new Typed(el.current, {
            strings: [newResult],
            typeSpeed: 20,
            showCursor: false,
          });
        }

        setResult(newResult);
      } else {
        throw new Error();
      }
    } catch (error) {
      message.error('The current mode is used by many people, the server is busy, please try again.');
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Drawing Prompts Hub - AI Art Prompts Generator</title>
        <link rel="canonical" href="https://drawingprompts.cc/art-prompts-generator" />
        <meta name="description" content="Elevate your artistic vision with the AI Art Prompts Generator. Instantly generate imaginative prompts to inspire your digital art, illustrations, and creative projects, harnessing the power of AI for boundless inspiration."/>
      </Helmet>
      <div>
        <h1 data-aos="fade-up" style={{
            fontWeight: 'bold',
            fontSize: '40px',
            color: '#333',
            textAlign: 'center',
            margin: '60px 0 20px'
        }}> AI Art Prompts Generator</h1>
      </div>
      <Row justify='space-between' style={{ padding: '40px 100px', }} gutter={[0, 16]}>
        <Col span={11}>
          <Card title="Input">
            <Form layout="vertical">
              <Form.Item label="Mode">
                <Select defaultValue="SD" style={{ width: '100%' }} onChange={handleModeChange}>
                  <Option value="SD">Stable Diffusion</Option>
                  <Option value="MJ">Midjourney</Option>
                  <Option value="DALLE">DALL·E</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Content">
                <Input.TextArea value={input} onChange={(e) => setInput(e.target.value)} />
              </Form.Item>
              <Form.Item>
                <Button style={{ marginTop: '20px' }} type="primary" onClick={handleClick}>Compute</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={11}>
          <Card title="Output">
            <div ref={el}>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ComputeContent;