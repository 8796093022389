const lightingRule = [
    {
      "pic": "https://i.postimg.cc/mZcDK41s/24995c61-8116-438d-8fe1-68d96f8b5539.jpg",
      "name": "Accent Lighting"
    },
    {
      "pic": "https://i.postimg.cc/Qx0y72SF/a318180b-c4b9-4d0c-9ccd-908ca60abf32.jpg",
      "name": "Backlight"
    },
    {
      "pic": "https://i.postimg.cc/vBF9DmV5/73a61588-50dc-426c-b4aa-740658d469e6.jpg",
      "name": "Blacklight"
    },
    {
      "pic": "https://i.postimg.cc/C5Dw5Fbn/57221df2-a9ed-4e4a-9955-c4d58670fb73.jpg",
      "name": "Blinding Light"
    },
    {
      "pic": "https://i.postimg.cc/RhqMB7Zd/582e0e6f-81f0-4a0f-a22f-5c093bca5f37.jpg",
      "name": "Candlelight"
    },
    {
      "pic": "https://i.postimg.cc/66GWYhF6/83034c40-3bd4-46d2-8095-f3ff5864f3ab.jpg",
      "name": "Concert Lighting"
    },
    {
      "pic": "https://i.postimg.cc/MHsmcSxx/baea08b1-a70d-4b85-ba45-f8851295eb30.jpg",
      "name": "Crepuscular Rays"
    },
    {
      "pic": "https://i.postimg.cc/Gtk1rX1k/1764216b-ef96-40b7-98fa-2ba2684a8e21.jpg",
      "name": "Direct Sunlight"
    },
    {
      "pic": "https://i.postimg.cc/rpnVYZ8s/08579c04-bf4d-4b6f-bc4d-76dbecf644a6.jpg",
      "name": "Dusk"
    },
    {
      "pic": "https://i.postimg.cc/tgPkBwdt/b6e806ea-1681-4e9f-ac0f-f600c89d5e8a.jpg",
      "name": "Edison Bulb"
    },
    {
      "pic": "https://i.postimg.cc/5tHTbsB6/36400801-0b99-4bb5-8f9e-a2231b0f896e.jpg",
      "name": "Electric Arc"
    },
    {
      "pic": "https://i.postimg.cc/Ls7DDRZs/17beacb0-9cb5-4f29-bca4-d9f0299e13db.jpg",
      "name": "Fire"
    },
    {
      "pic": "https://i.postimg.cc/pVgNrbGR/6f27ee82-632e-40f9-b015-48c0b7f32fcf.jpg",
      "name": "Fluorescent"
    },
    {
      "pic": "https://i.postimg.cc/zGdv9G7q/ef296e36-ef15-480f-a225-c7ff9993b7dd.jpg",
      "name": "Glowing"
    },
    {
      "pic": "https://i.postimg.cc/655Z8gyb/80dad965-847c-4b76-8977-86db3a98c7de.jpg",
      "name": "Glowing Radioactively"
    },
    {
      "pic": "https://i.postimg.cc/fbZShPnv/cf4f795e-a4a4-483a-a135-45c57282b7e4.jpg",
      "name": "Glowstick"
    },
    {
      "pic": "https://i.postimg.cc/JzXj8JyR/bf23c471-f388-48e1-8ba3-c8712599b395.jpg",
      "name": "Lava Glow"
    },
    {
      "pic": "https://i.postimg.cc/4yrn2LQV/cf51b1a9-2a39-4477-8961-4bb252ac8235.jpg",
      "name": "Moonlight"
    },
    {
      "pic": "https://i.postimg.cc/zvfnYchQ/bf896b79-f8aa-4505-a83f-89c245b763f3.jpg",
      "name": "Natural Lighting"
    },
    {
      "pic": "https://i.postimg.cc/BnTmJRyY/5fb3c2ad-c229-48d2-a144-e27119eb5740.jpg",
      "name": "Neon Lamp"
    },
    {
      "pic": "https://i.postimg.cc/L6Htzh0T/37ce1df7-ff4f-49b3-9128-e0100096c9b9.jpg",
      "name": "Nightclub Lighting"
    },
    {
      "pic": "https://i.postimg.cc/SxVYkrSF/e77c6950-c9e1-4fba-8a4a-55edebf90b5d.jpg",
      "name": "Nuclear Waste Glow"
    },
    {
      "pic": "https://i.postimg.cc/rwRLgyDd/0eb8dcbc-4b73-46ea-a72b-4a85deaad381.jpg",
      "name": "Quantum Dot Display"
    },
    {
      "pic": "https://i.postimg.cc/7LQsR0jw/08bc7699-9f1a-416f-8c73-c4e242170433.jpg",
      "name": "Spotlight"
    },
    {
      "pic": "https://i.postimg.cc/brKjf4MY/0842fa97-645e-44a9-af56-36c8d63be22a.jpg",
      "name": "Strobe"
    },
    {
      "pic": "https://i.postimg.cc/DyLqkL4n/dc29021a-6d03-403e-9a8b-bfc041c19213.jpg",
      "name": "Sunlight"
    },
    {
      "pic": "https://i.postimg.cc/KYhCNPXV/3cb19962-d787-48c0-a44f-c01c9066fe72.jpg",
      "name": "Ultraviolet"
    }
  ]

export { lightingRule }