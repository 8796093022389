import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{
      padding: '40px 60px'
    }}>
      <h1>Privacy Policy</h1>
      <p>
        At Drawing Prompts Hub, we are committed to protecting the privacy of our users. This privacy policy explains what information we collect, how we use it, and the steps we take to ensure its security.
      </p>
      <h2>Scope of This Policy</h2>
      <p>
        This policy applies to all online activities on Drawing Prompts Hub and to any information shared or collected through our website. It does not apply to information collected offline or through other channels.
      </p>
      <h2>Consent</h2>
      <p>
        By using our website, you consent to the collection, use, and disclosure of your information as described in this policy.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We collect information that you provide to us voluntarily. This may include your name, email address, and any other personal details you choose to share when you interact with us, such as through contact forms, newsletter subscriptions, or feedback.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We use the information we collect to:
        <ul>
          <li>Provide, maintain, and improve our website and services.</li>
          <li>Respond to your inquiries and provide customer support.</li>
          <li>Send you updates, newsletters, and marketing communications.</li>
          <li>Analyze trends and user behavior to enhance our website and services.</li>
          <li>Protect against fraud and abuse.</li>
        </ul>
      </p>
      <h2>Log Files</h2>
      <p>
        Like many websites, Drawing Prompts Hub uses log files to monitor website traffic. These files may record information such as your IP address, browser type, internet service provider (ISP), date and time of your visit, and the number of clicks. This data is used to analyze trends and administer the site, and is not linked to personally identifiable information.
      </p>
      <h2>Third-Party Cookies and Advertising</h2>
      <p>
        We may use third-party cookies for advertising and analytics purposes. These cookies collect information about your online activities to deliver targeted ads and measure the effectiveness of our marketing campaigns. You can opt-out of these cookies by adjusting your browser settings.
      </p>
      <h2>Children's Privacy</h2>
      <p>
        Drawing Prompts Hub does not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        We reserve the right to update this privacy policy at any time. We will notify you of any significant changes by posting the new policy on our website.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about our privacy policy, please feel free to contact us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;