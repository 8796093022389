// index.tsx
import React from 'react';
import styles from './index.module.css'
import { Card, Col, Row } from 'antd';
import { Divider, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Home = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Art prompt generator - Midjourney prompts | Stable diffusion prompts</title>
                <link rel="canonical" href="https://drawingprompts.cc/" />
                <meta name="description" content="An AI Drawing Prompts Hub that utilizes algorithmic model for automatic prompt expansion and image-based description generation. Supports Midjourney, Stable Diffusion, and DALL·E prompt generation tools." />
            </Helmet>
            <div style={{
                padding: '0 40px 20px',
            }}>
                <div>
                    <h1 data-aos="fade-up" className={styles.homeTitle}> Drawing Prompts Hub </h1>
                </div>
                <Row gutter={16}>
                    <Col span={8}>
                        <Link to="/best-midjourney-prompts">
                            <Card className={styles.homeCard} title="Best Midjourney Prompts">🚀 Best midjourney prompts, including the corresponding prompts for each image.</Card>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <Link to="/midjourney-prompts-generator">
                            <Card className={styles.homeCard} title="Midjourney Prompts Generator">🔧 A tool for assembling Midjourney prompts, paving the way for drawing.</Card>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <Link to="/art-prompts-generator">
                            <Card className={styles.homeCard} title="AI Art Prompts Generator">🎨 An AI algorithm model that expands your prompts, including prompt word models for Midjourney, Stable Diffusion, and DALL·E.</Card>
                        </Link>
                    </Col>
                </Row>
                <Row style={{ marginTop: '30px' }} gutter={16}>
                    <Col span={8}>
                        <Link to="/image-to-text">
                            <Card className={styles.homeCard} title="AI Image To Text">🤖️ AI generates descriptive words related to the image.</Card>
                        </Link>
                    </Col>
                    <Col span={8}>
                        <Card className={styles.homeCard} title="ChatGPT Prompts">🚧 Emerging. A ChatGPT prompts generator. </Card>
                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>


                <Divider style={{marginTop: '160px'}} />
                <Card>
                    <h2>Frequently Asked Questions</h2>
                    <Collapse
                    defaultActiveKey={['1','2','3','4','5']}
                        size="large"
                        ghost
                        items={[
                            {
                                key: '1',
                                label: 'What Features Does Drawing Prompts Hub Offer?',
                                children:
                                    <div>
                                        <h3>What Features Does Drawing Prompts Hub Offer?</h3>
                                        <p>The Drawing Prompts Hub is a platform designed to enhance the creative process for artists and designers by leveraging the power of AI. Here are some of the key features that set our Hub apart:<br />

                                            1. AI-Powered Prompt Expansion: Our platform uses advanced algorithms to automatically expand and refine your initial drawing prompts, providing you with a wealth of new ideas and inspiration.<br />

                                            2. Image-Based Description Generation: With the ability to generate descriptions based on images, our tool helps artists conceptualize their ideas more effectively. This feature is particularly useful for brainstorming and refining visual concepts.<br />

                                            3. Integration with Midjourney Prompts: We support Midjourney Prompts, allowing users to generate prompts that align with the Midjourney style, which is known for its unique and imaginative approach to art.<br />

                                            4. Prompt Generation Tools: Our Hub includes tools that are compatible with popular AI art generators like Stable Diffusion and DALL·E, enabling users to create prompts that can be directly used with these tools.<br />

                                            5. Image Examples: To help users understand how prompts can be transformed into visual art, we provide image examples that demonstrate the potential outcomes of using our AI-generated prompts.<br />

                                            6. User-Friendly Interface: Our platform is designed with ease of use in mind, ensuring that artists of all skill levels can take advantage of our AI-powered features.<br />

                                            7. Continuous Updates and Improvements: As AI technology evolves, we continuously update our algorithms to ensure that our users have access to the latest advancements in AI-assisted creativity.<br />

                                            8. Support and Resources: Our team is dedicated to providing support and resources to help users make the most of our platform. This includes tutorials, FAQs, and direct assistance when needed.<br />

                                            9. Accessibility: Our Hub is designed to be accessible across various devices and platforms, ensuring that artists can engage in the creative process wherever they are.</p>
                                    </div>
                            },
                            {
                                key: '2',
                                label: 'What Are Prompts and Why Are They Important?',
                                children:
                                    <div>
                                        <h3>What Are Prompts and Why Are They Important?</h3>
                                        <p>Prompts, in the context of the Midjourney Bot, are concise text phrases that serve as the foundation for the AI to interpret and generate an image. These prompts are meticulously dissected by the bot into smaller units known as tokens. These tokens are then compared against the bot's extensive training data, which consists of a vast array of images and their associated descriptions. This comparison process allows the bot to understand the intent behind the prompt and subsequently create an image that aligns with the user's vision.<br></br>

                                            The significance of a well-crafted prompt cannot be overstated. It acts as the bridge between human creativity and AI-generated art. A prompt that is clear, specific, and rich in detail can guide the Midjourney Bot to produce images that are not only visually stunning but also uniquely reflective of the user's artistic intent. It's through this careful crafting of prompts that artists and designers can harness the power of AI to bring their most imaginative ideas to life, pushing the boundaries of what is possible in the realm of digital art creation.</p>
                                    </div>
                            },
                            {
                                key: '3',
                                label: 'How Do AI Image Generation Prompts Work?',
                                children:
                                    <div>
                                        <h3>How Do AI Image Generation Prompts Work?</h3>
                                        <p>AI image generation prompts are powered by advanced vision-language pre-training (VLP) models, which have significantly improved performance in various tasks that involve both visual and textual information. However, most pre-existing models tend to specialize in either understanding-based tasks, where the model interprets and comprehends visual and textual data, or generation-based tasks, where the model creates new content based on input.<br />

                                            The proposed BLIP (Vision-Language Pre-training) framework addresses this limitation by flexibly transferring its learnings to both understanding and generation tasks. BLIP's innovative approach involves effectively utilizing noisy web data, which is often collected from images paired with text from the internet. This data can be imperfect, but BLIP mitigates this by bootstrapping the captions.<br />

                                            In this process, a captioner component within BLIP generates synthetic captions for images, and a filter component removes the noisy or irrelevant captions. This method of iterative refinement ensures that the model learns from high-quality, relevant data, which is crucial for its performance on vision-language tasks.<br />

                                            BLIP has demonstrated state-of-the-art results across a variety of tasks, including image-text retrieval, where it improved average recall@1 by 2.7%, image captioning with a 2.8% increase in CIDEr score, and Visual Question Answering (VQA) with a 1.6% increase in VQA score. Furthermore, BLIP exhibits strong generalization capabilities, as it can be directly applied to video-language tasks without any additional training, a process known as zero-shot learning.<br />

                                            The BLIP framework's success lies in its ability to adapt to both understanding and generation tasks, making it a versatile tool for AI-driven image generation. By effectively managing and learning from noisy web data, BLIP has set new benchmarks in the field of vision-language tasks, showcasing the potential of AI in creating and understanding visual content.</p>
                                    </div>
                            },
                            {
                                key: '4',
                                label: 'Is the Service Free and How Is My Privacy Protected?',
                                children:
                                    <div>
                                        <h3>How Do AI Image Generation Prompts Work?</h3>
                                        <p>Our Drawing Prompts Hub is designed to be accessible and user-friendly, which is why we offer it completely free of charge. There's no need to create an account or log in, ensuring that your personal information remains private. We respect your privacy and have implemented measures to ensure that no user data is collected or stored. This allows you to enjoy the creative benefits of our AI-powered features without any concerns about data security.</p>
                                    </div>
                            },
                            {
                                key: '5',
                                label: 'Is There a Limit to the Number of Times I Can Use the Prompt Generator?',
                                children:
                                    <div>
                                        <h3>How Do AI Image Generation Prompts Work?</h3>
                                        <p>Currently, our Drawing Prompts Hub is entirely free to use, and there is no limit on the number of times you can generate prompts. Feel free to explore and create as much as you like, using our AI-powered tools to fuel your artistic endeavors.</p>
                                    </div>
                            },
                        ]}
                    />

                </Card>
                <Divider />
                <Card title="Support Us">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <a href='https://ko-fi.com/E1E3T20XN' target='_blank'><img height='40' style={{ border: '0px', height: '45px', marginBottom: '10px' }} src='https://storage.ko-fi.com/cdn/kofi3.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
                        <a href="https://www.producthunt.com/posts/drawing-prompts-hub?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-drawing&#0045;prompts&#0045;hub" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=434750&theme=light" alt="Drawing&#0032;Prompts&#0032;Hub - Drawing&#0032;Prompts&#0032;Hub&#0032;Fuels&#0032;Creativity&#0044;&#0032;Makes&#0032;AI&#0032;Art&#0032;Easy&#0046; | Product Hunt" style={{ width: '200px', height: '45px' }} /></a>
                    </div>
                </Card>
            </div>

        </div>

    );
}

export default Home;

