import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { blogData } from './blogsData'; // 导入博客数据
import './detail.css'; 

const Detail = () => {
  // 使用useParams钩子获取路由参数
  const { id } = useParams();
  // 根据ID从数据源中找到博客
  const blog = blogData.find(blog => blog.id === parseInt(id, 10));

  // 如果没有找到对应的博客，显示错误信息
  if (!blog) {
    return <div>not found</div>;
  }

  return (
    <div> 
      <Helmet>
        <meta charSet="utf-8" />
        <title>Drawing Prompts Hub - Blog</title>
        <meta name="description" content="An AI Drawing Prompts Hub that utilizes algorithmic model for automatic prompt expansion and image-based description generation. Supports Midjourney, Stable Diffusion, and DALL·E prompt generation tools." />
      </Helmet>
      <div className="blog-detail">
      <h1>{blog.title}</h1>
      <div className='time'>{blog.date}</div>
      <div dangerouslySetInnerHTML={{ __html: blog.content }} />
    </div>
    </div>
  );
};

export default Detail;