import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Masonry from 'react-masonry-css';
import { Helmet } from "react-helmet";
import { Modal, Button, Row, Col } from 'antd';
import Image from './image'
import './index.css'


// 最大数据数量
let FILE_MAX_LENGTH = 10000
let $axios = axios

// 定义每列显示的项目数
const itemsPerPage = 10;

// 设置分割数组的函数
const chunkArray = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

const Example = () => {
  // 初始化状态时，增加 allData 用于储存所有数据
  const [allData, setAllData] = useState([]); 
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);


  // 分页函数
  const loadMore = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);

    const moreData = chunkArray(allData, itemsPerPage)[newPage - 1];
  
    setData([...data, ...moreData]);
  };

  // 设置Masonry布局的响应式份数
  const breakpointColumnsObj = { default: 4, 1100: 3, 700: 2, 500: 1 };
  
  const handleImageClick = item => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(selectedItem.clean_prompts);
    alert('Prompts copied to clipboard!');
  };

  function getRandomNumber() {
    return Math.floor(Math.random() * 500);
  }

   // 一次读取并解析CSV文件
   useEffect(() => {
    $axios.get('mjexample2.csv')
      .then(response => {
         // 处理 CSV 文件内容
        const csvData = response.data;
        const results = [];
        const parsedData = csvData.trim().split('\n').map(row => row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(cell => cell.trim()));

        const headers = parsedData[0];

        for (let i = getRandomNumber() * 10; i < FILE_MAX_LENGTH; i++) {
          const rowData = parsedData[i];
          const rowObject = {};

          for (let j = 0; j < headers.length; j++) {
            rowObject[headers[j]] = rowData[j];
          }

          results.push(rowObject);
        }
        setAllData(results);
        const firstPageData = chunkArray(results, itemsPerPage)[0];   // 使用我们自定义的chunkArray
        setData(firstPageData);
      })
      .catch(error => { console.error('Error reading CSV file:', error); });
  }, []);



  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Drawing Prompts Hub - Best Midjourney Prompts</title>
        <link rel="canonical" href="https://drawingprompts.cc/best-midjourney-prompts" />
        <meta name="description" content="Discover the top Midjourney Prompts to elevate your creative journey. Unleash your imagination with these expertly curated ideas, perfect for writers, artists, and innovators seeking inspiration."/>
      </Helmet>
      <div>
        <h1 data-aos="fade-up" className="example-title"> Best Midjourney Prompts </h1>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data.map((item, index) => (
          <div key={`item-${index}`} onClick={() => handleImageClick(item)}>
            <Image src={item.Attachments}></Image>
          </div>
        ))}
      </Masonry>
      {currentPage < chunkArray(allData, itemsPerPage).length && 
         <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
          <Button 
            onClick={loadMore} 
            style={{ 
              width: '200px', 
              height: '40px', 
              fontSize: '20px' 
            }}
          >
            View More
          </Button>
        </div>
      }
      {selectedItem && (
        <Modal
          title="Image Details"
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          footer={<Button onClick={handleCopy}>COPY</Button>}
        >
          <Row gutter={16}>
            <Col span={12}>
              <img src={selectedItem.Attachments} style={{ width: '100%' }} alt="Modal" />
            </Col>
            <Col span={12}>
              <p>{selectedItem.clean_prompts}</p>
            </Col>
          </Row>
        </Modal>
      )}
     
    </div>
  );
};

export default Example;
