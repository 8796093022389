import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{
      padding: '40px 60px'
    }} className="terms-of-service">
      <h1>Welcome to Drawing Prompts Hub!</h1>
      <p>By accessing this website, you agree to the following terms and conditions.</p>
      <h2>Terms and Conditions</h2>
      <p>Do not continue to use Drawing Prompts Hub if you do not agree to all of the terms and conditions stated on this page.</p>
      <h3>Terminology</h3>
      <p>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements:</p>
      <ul>
        <li>"Client," "You," and "Your" refer to you, the person who logs on this website and complies with the Company's terms and conditions.</li>
        <li>"The Company," "Ourselves," "We," "Our," and "Us" refer to our Company.</li>
        <li>"Party," "Parties," or "Us" refer to both the Client and ourselves.</li>
      </ul>
      <h3>Cookies</h3>
      <p>We employ the use of cookies. By accessing Drawing Prompts Hub, you agree to the use of cookies in accordance with Drawing Prompts Hub's Privacy Policy.</p>
      <h3>Hyperlinking to our Content</h3>
      <p>The following organizations may link to our Website without prior written approval:</p>
      <ul>
        <li>Government agencies.</li>
        <li>Search engines.</li>
        <li>News organizations.</li>
        <li>Online directory distributors.</li>
        <li>System-wide Accredited Businesses (with exceptions).</li>
      </ul>
      <h3>iFrames</h3>
      <p>Without prior approval and written permission, you may not create frames around our Webpages that alter the visual presentation or appearance of our Website.</p>
      <h3>Content Liability</h3>
      <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that arise on your Website.</p>
      <h3>Reservation of Rights</h3>
      <p>We reserve the right to request that you remove all links or any particular link to our Website. You agree to immediately remove all links to our Website upon request.</p>
      <h3>Removal of Links from Our Website</h3>
      <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment.</p>
      <h3>Disclaimer</h3>
      <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website.</p>
      <p>Nothing in this disclaimer will limit or exclude our or your liability for death or personal injury, fraud or fraudulent misrepresentation, or any of our or your liabilities that may not be excluded under applicable law.</p>
    </div>
  );
};

export default TermsOfService;